import type { ItemType } from '../../../types/enums';
import { TagViewVariant } from '../../../types/enums';
import type { ItemViewTypeAndProps, ItemViewVariant } from '../../entities/types';
import type { TagViewCommonProps } from './types';
import inlineDefaultProps from './TagViewInline/TagViewInlineDefaultProps';
import tableViewDefaultProps from './TagViewTableRow/TagViewTableRowDefaultProps';

const variantToComponentAndDefaultPropsMap: Record<TagViewVariant, TagViewCommonProps> = {
  inline: inlineDefaultProps,
  chip: null,
  tableRow: tableViewDefaultProps
};

/**
 * Merge default props for variant with props supplied with the variant, and additional provided props.
 * @param variant
 * @param additionalProps
 */
function mergeVariantProps<PropsType>(
  variant: ItemViewTypeAndProps<ItemType.TAG, ItemViewVariant<ItemType.TAG>> = {
    type: TagViewVariant.INLINE
  },
  additionalProps?: PropsType
): PropsType {
  const defaultProps = variantToComponentAndDefaultPropsMap[variant?.type];
  return { ...defaultProps, ...variant?.props, ...additionalProps };
}

export default mergeVariantProps;
