import type { ItemType } from '../../../types/enums';
import { GuideViewVariant } from '../../../types/enums';
import type { ItemViewTypeAndProps, ItemViewVariant } from '../../entities/types';
import type { GuideViewCommonProps } from './type';
import inlineDefaultProps from './GuideViewInline/GuideViewInlineDefaultProps';
import cardDefaultProps from './GuideViewCard/GuideViewCardDefaultProps';

const variantToComponentAndDefaultPropsMap: Record<GuideViewVariant, GuideViewCommonProps> = {
  inline: inlineDefaultProps,
  card: cardDefaultProps
};

/**
 * Merge default props for variant with props supplied with the variant, and additional provided props.
 * @param variant
 * @param additionalProps
 */
function mergeVariantProps<PropsType>(
  variant: ItemViewTypeAndProps<ItemType.GUIDE, ItemViewVariant<ItemType.GUIDE>> = {
    type: GuideViewVariant.INLINE
  },
  additionalProps?: PropsType
): PropsType {
  const defaultProps = variantToComponentAndDefaultPropsMap[variant?.type];
  return { ...defaultProps, ...variant?.props, ...additionalProps };
}

export default mergeVariantProps;
