import type {
  ApolloQueryResult,
  DocumentNode,
  FetchMoreOptions,
  FetchMoreQueryOptions,
  QueryResult
} from '@apollo/client';
import { getPropertyByPath } from '@aurora/shared-utils/helpers/objects/ObjectHelper';
import { getLog } from '@aurora/shared-utils/log';
import type { Connection, PageInfo } from '@aurora/shared-generated/types/graphql-schema-types';

const log = getLog(module);

export type FetchMore<TData, TVariables> = ((
  fetchMoreOptions: FetchMoreQueryOptions<TVariables, TData> & FetchMoreOptions<TData, TVariables>
) => Promise<ApolloQueryResult<TData>>) &
  ((
    fetchMoreOptions: {
      query?: DocumentNode;
    } & FetchMoreQueryOptions<TVariables, TData> &
      FetchMoreOptions<TData, TVariables>
  ) => Promise<ApolloQueryResult<TData>>);

export enum PaginationDirection {
  PREV = 'prev',
  NEXT = 'next',
  FIRST = 'first'
}

export enum PaginationUpdateStrategy {
  APPEND = 'append',
  PREPEND = 'prepend',
  REPLACE = 'replace'
}

interface ConnectionResult extends Connection {
  __typename: string;
}

/**
 * Helper for pagination for Apollo-based GraphQL queries.
 *
 */
export default class PaginationHelper<TData, TVariables> {
  private readonly fetchMore: FetchMore<TData, TVariables>;

  public readonly pageInfo: Partial<PageInfo>;

  private readonly pageSize: number;

  private readonly connectionPath: string;

  private readonly variables: TVariables | undefined;

  private readonly onUpdate:
    | ((newConnection: Connection, fetchMoreResult?: TData) => TData)
    | undefined;

  private readonly onPageChange:
    | ((newConnection: Connection, variables: TVariables) => void)
    | undefined;

  private readonly initialData: TData | undefined;

  /**
   * @param fetchMore - a callback to run when pagination occurs.
   * @param pageInfo - pagination information from the REST response about the list of results
   * @param connectionPath - dot notation path down to the list items inside of the query result data.
   * @param pageSize - number of items per page
   * @param variables - an optional set of variables to construct the query.
   * @param onUpdate - an optional function to transform the result of the returned connection. Useful in cases
   * where the connection may be a nested child of a parent object in the query, such as message replies.
   * @param onPageChange - an optional callback function to fetch state of current page
   * @param initialData - an optional parameter when the `fetchMore` associated to the query has not run
   * yet and the previous data, from the query, is undefined. This is useful in use cases where the query
   * was skipped and the `fetchMore` is used to get more pages.
   */
  constructor(
    fetchMore: FetchMore<TData, TVariables>,
    pageInfo: Partial<PageInfo>,
    connectionPath: string,
    pageSize: number,
    variables?: TVariables | undefined,
    onUpdate?: ((newConnection: Connection, fetchMoreResult?: TData) => TData) | undefined,
    onPageChange?: ((newConnection: Connection, variables: TVariables) => void) | undefined,
    initialData?: TData | undefined
  ) {
    this.fetchMore = fetchMore;
    this.pageInfo = pageInfo;
    this.connectionPath = connectionPath;
    this.variables = variables;
    this.pageSize = pageSize;
    this.onUpdate = onUpdate;
    this.onPageChange = onPageChange;
    this.initialData = initialData;
    if (!pageSize) {
      throw new Error('No page size defined for PaginationHelper!');
    }
  }

  private buildVariables(direction: PaginationDirection): TVariables {
    const variables = this.variables ?? {};

    if (direction === PaginationDirection.PREV) {
      return {
        ...variables,
        last: this.pageSize,
        before: this.pageInfo.startCursor,
        first: null,
        after: null
      } as unknown as TVariables;
    } else if (direction === PaginationDirection.FIRST) {
      return {
        ...variables,
        first: this.pageSize,
        last: null,
        before: null,
        after: null
      } as unknown as TVariables;
    } else {
      return {
        ...variables,
        first: this.pageSize,
        after: this.pageInfo.endCursor,
        before: null,
        last: null
      } as unknown as TVariables;
    }
  }

  /**
   * Loads the next or previous page of results.
   *
   * @param updateStrategy - The strategy in which the existing results are updated. One of:
   * append - appends new page to end of current results, prepend - prepends new page
   * to beginning of current results, replace - replace new page with existing results.
   * @param direction - The direction in which to load a page: prev or next.
   * @param useLoadPreviousNext - to identify if we are using this for load both prev and next show more.
   * @return a promise to the loaded page query results.
   */
  loadPage = (
    updateStrategy: PaginationUpdateStrategy = PaginationUpdateStrategy.APPEND,
    direction: PaginationDirection = PaginationDirection.NEXT,
    useLoadPreviousNext = false
  ): Promise<ApolloQueryResult<TData>> => {
    const [name1, name2] = this.connectionPath.split('.');
    const connectionName = name2 ?? name1;
    return this.fetchMore({
      variables: this.buildVariables(direction),
      updateQuery: (previousResult, { fetchMoreResult }): TData => {
        const connection = getPropertyByPath<TData, Connection>(
          fetchMoreResult,
          this.connectionPath,
          null
        );
        if (!connection) {
          log.error('Error retrieving new page of data');
          return previousResult;
        }

        const newEdges = connection?.edges || [];
        const newPageInfo = connection?.pageInfo;

        const previousConnection = getPropertyByPath<TData, Connection>(
          previousResult ?? (this.initialData as unknown as TData),
          this.connectionPath,
          null
        ) as unknown as ConnectionResult;

        if (useLoadPreviousNext && direction == PaginationDirection.PREV) {
          newPageInfo.endCursor = previousConnection?.pageInfo.endCursor;
          newPageInfo.hasNextPage = previousConnection?.pageInfo.hasNextPage;
        }

        if (useLoadPreviousNext && direction == PaginationDirection.NEXT) {
          newPageInfo.startCursor = previousConnection?.pageInfo.startCursor;
          newPageInfo.hasPreviousPage = previousConnection?.pageInfo.hasPreviousPage;
        }

        const previousEdges = previousConnection?.edges ?? [];
        const totalCount = previousConnection?.totalCount;

        const typename =
          (connection as unknown as ConnectionResult)?.__typename ?? previousConnection.__typename;
        const updatedEdges = PaginationHelper.mergeItems(updateStrategy, previousEdges, newEdges);

        const newConnection = {
          __typename: typename,
          pageInfo: newPageInfo,
          edges: updatedEdges,
          totalCount
        };

        if (this.onPageChange) {
          this.onPageChange(newConnection, this.buildVariables(direction));
        }
        return (
          this.onUpdate
            ? this.onUpdate(newConnection, fetchMoreResult)
            : {
                [connectionName]: newConnection
              }
        ) as TData;
      }
    });
  };

  /**
   * Returns a new pagination helper instance based off of the provided queryResult and arguments. Provides the ability
   * to page through nested collections via the itemPath and onUpdate arguments
   *
   * @param queryResult the queryResult returned by useQuery
   * @param pageSize the number of results
   * @param itemPath the path to the pagable connection. For example, 'users', or 'user.roles' for a nested collection
   * @param onUpdate callback function invoked when a refetch is executed. For use with nested connections, it should
   *  return a modified cache object which includes the connection and any other required fields. Avoid adding setState
   *  calls here and rely on returning the correct object to update the cache
   * @param onPageChange callback function invoked when a refetch is executed. Does not modify the cache
   */
  static fromQueryResult<TData, TVariables>(
    queryResult: QueryResult<TData, TVariables>,
    pageSize: number,
    itemPath: string,
    onUpdate?: ((newConnection: Connection, fetchMoreResult?: TData) => TData) | undefined,
    onPageChange?: ((newConnection: Connection, variables: TVariables) => void) | undefined
  ): PaginationHelper<TData, TVariables> {
    const finalItemPath = itemPath || Object.keys(queryResult?.data || {})[0] || '';

    const pageInfo =
      getPropertyByPath<QueryResult<TData, TVariables>, PageInfo>(
        queryResult,
        `data.${finalItemPath}.pageInfo`
      ) || {};

    return new PaginationHelper<TData, TVariables>(
      queryResult.fetchMore,
      pageInfo,
      finalItemPath,
      pageSize,
      queryResult.variables,
      onUpdate,
      onPageChange
    );
  }

  /**
   * Merge existing and new item arrays based on the specified `PaginationUpdateStrategy`.
   *
   * @param updateStrategy the strategy for merging the item arrays
   * @param existingItems the existing items
   * @param newItems the new items
   */
  static mergeItems<ItemType>(
    updateStrategy: PaginationUpdateStrategy,
    existingItems: Array<ItemType>,
    newItems: Array<ItemType>
  ): Array<ItemType> {
    let updatedItems;
    switch (updateStrategy) {
      case PaginationUpdateStrategy.APPEND: {
        updatedItems = [...existingItems, ...newItems];
        break;
      }
      case PaginationUpdateStrategy.PREPEND: {
        updatedItems = [...newItems, ...existingItems];
        break;
      }
      case PaginationUpdateStrategy.REPLACE: {
        updatedItems = [...newItems];
        break;
      }
      default: {
        updatedItems = [...existingItems];
      }
    }
    return updatedItems;
  }
}
