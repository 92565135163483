/**
 * Makes a function call in a predetermined time interval
 * irrespective of continuous user actions.
 *
 * @param callback
 * @param limit
 */
function throttle(callback: () => void, limit: number): () => void {
  let lastCallback;
  let lastRan;

  return () => {
    if (!lastRan) {
      callback();
      lastRan = Date.now();
    } else {
      clearTimeout(lastCallback);
      lastCallback = setTimeout(() => {
        if (Date.now() - lastRan >= limit) {
          callback();
          lastRan = Date.now();
        }
      }, limit - (Date.now() - lastRan));
    }
  };
}

function debounce<
  CallbackFunction extends (
    ...parameters: Parameters<CallbackFunction>
  ) => ReturnType<CallbackFunction>
>(callback: CallbackFunction, waitFor: number) {
  let timeout: NodeJS.Timeout;

  return (...parameters: Parameters<CallbackFunction>) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => callback(...parameters), waitFor);
  };
}

export { throttle, debounce };
