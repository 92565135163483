import type { Dispatch, SetStateAction } from 'react';
import { useMemo, useState } from 'react';
import { useIsomorphicLayoutEffect } from 'react-use';
import { ResizeObserverEntry } from '@juggle/resize-observer';
import { canUseDOM } from 'exenv';
import { debounce } from '../helpers/ui/ThrottleHelper';

export type UseMeasureResult<E extends HTMLElement = HTMLElement> = {
  /**
   * A ref to the element to measure.
   */
  ref: Dispatch<SetStateAction<E>>;
  /**
   * Size information about the element being measured.
   */
  entry: ResizeObserverEntry;
  /**
   * The measured element.
   */
  element: E | null;
};

/**
 * Returns a polyfilled ResizeObserverEntry @link https://developer.mozilla.org/en-US/docs/Web/API/ResizeObserverEntry
 *
 * @param element
 */
function getResizeObserverEntryPolyfill(element: HTMLElement): ResizeObserverEntry | null {
  if (element) {
    return new ResizeObserverEntry(element);
  }
  return null;
}

/**
 * A copy of the react-use `useMeasureEnhanced` hook that returns additional details about the
 * matching entry, specifically it provides access to the `ResizeObserverEntry` and all
 * it's fields.
 *
 * Taken from https://github.com/streamich/react-use/blob/master/docs/useMeasure.md
 *
 * @param useHook whether to use the hook which will apply a resize observer
 * @param debounceMs the debounce time used on the resize observer, defaults to 0
 *
 * @author Adam Ayres
 */
const useMeasureEnhanced = <E extends HTMLElement = HTMLElement>(
  useHook = true,
  debounceMs = 0
): UseMeasureResult<E> => {
  const [element, ref] = useState<E | null>(null);
  const [entry, setEntry] = useState<ResizeObserverEntry>();

  const observer = useMemo((): ResizeObserver | null => {
    if (canUseDOM) {
      return new ResizeObserver(
        debounce<ResizeObserverCallback>(entries => {
          const [localEntry] = entries;
          if (localEntry) {
            if (!localEntry.borderBoxSize) {
              // Safari does not support some ResizeObserverEntry properties, such as `borderBoxSize`, so we polyfill here
              const newEntry = getResizeObserverEntryPolyfill(element);
              setEntry(newEntry);
            } else {
              const { borderBoxSize } = localEntry;
              // The borderBoxSize is not consistently returned as an array, so we make it always return an array here
              if (!Array.isArray(borderBoxSize)) {
                Object.defineProperty(localEntry, 'borderBoxSize', {
                  value: [borderBoxSize],
                  writable: false
                });
              }
              setEntry(localEntry);
            }
          }
        }, debounceMs)
      );
    }
    return null;
  }, [debounceMs, element]);

  useIsomorphicLayoutEffect(() => {
    if (element && useHook) {
      observer.observe(element);
    }

    return (): void => {
      if (canUseDOM && observer && useHook) {
        observer.disconnect();
      }
    };
  }, [element, observer, useHook]);

  return { ref, entry, element };
};

export default useMeasureEnhanced;
