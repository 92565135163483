export enum ListVariant {
  LIST_GROUP = 'listGroup',
  UNSTYLED = 'unstyled',
  CARD = 'card',
  GRID = 'grid',
  TABLE = 'table',
  TABLE_DRAGGABLE = 'tableDraggable',
  TABLE_DRAGGABLE_NESTED = 'tableDraggableNested',
  UNWRAPPED = 'unwrapped'
}

export enum ListItemSpacing {
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
  XL = 'xl',
  XXL = 'xxl',
  NONE = 'none'
}

export enum GridType {
  GRID = 'grid',
  FLEX = 'flex'
}

export enum CardLayout {
  GROUP = 'group',
  DECK = 'deck',
  COLUMNS = 'columns'
}

export enum MessageSorts {
  POST_TIME = 'postTime',
  KUDOS_SUM_WEIGHT = 'kudosSumWeight',
  VIEWS_COUNT = 'viewsCount',
  REPLIES_COUNT = 'repliesCount',
  REJECTED_TIME = 'lastRejectDate',
  LATEST_EDIT_TIME = 'latestEditTime'
}

export enum SpamSorts {
  REPORT_DATE = 'reportDate'
}

export enum RejectedSorts {
  REJECTED_DATE = 'lastRejectDate'
}

export enum AbuseSorts {
  REPORT_DATE = 'reportedDate',
  REPORTERS_COUNT = 'reportersCount',
  VIEWS_COUNT = 'viewsCount',
  KUDOS_COUNT = 'kudosCount',
  REPLIES_COUNT = 'repliesCount'
}

export enum CasePortalSorts {
  LAST_MODIFIED_DATE = 'lastModifiedDate',
  CREATED_DATE = 'createdDate'
}

export enum TagSorts {
  TAG_TEXT = 'text',
  LAST_USED = 'time',
  FOLLOWERS_COUNT = 'followersCount',
  POST_COUNT = 'postCount'
}

export enum FilterSorts {
  TIMESTAMP = 'timestamp'
}
