import type { UserLoginFragment } from '@aurora/shared-generated/types/graphql-types';
import React from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import { SharedComponent } from '../../../enums';
import useTranslation from '../../useTranslation';

interface Props {
  /**
   * The user to display the login for.
   */
  user: UserLoginFragment;
  /**
   * Set a custom element for this component.
   */
  as?: React.ElementType;
  /**
   * Class name(s) to apply to the component element.
   */
  className?: string;
  /**
   * User login snippet to be highlighted (to be only used for displaying search results)
   */
  searchSnippet?: string;
  /**
   * Use clamp to clip the text which overflows with ... (eg. autho...).
   */
  useClamp?: boolean;
}

/**
 * Display a user's login.
 *
 * @constructor
 *
 * @author Adam Ayres
 */
const UserLogin: React.FC<React.PropsWithChildren<Props>> = ({
  user,
  as: Component = 'span',
  className,
  searchSnippet,
  useClamp
}) => {
  const cx = useClassNameMapper();
  const { formatMessage, loading: textLoading } = useTranslation(SharedComponent.USER_LOGIN);
  const { login } = user || {};

  return !textLoading && login ? (
    <Component
      data-testid="userLogin"
      className={cx(className, { 'text-break lia-g-clamp': useClamp })}
    >
      {searchSnippet ? (
        <p className={cx('lia-g-mb-0')} dangerouslySetInnerHTML={{ __html: searchSnippet }}></p>
      ) : (
        formatMessage('title', { login })
      )}
    </Component>
  ) : null;
};

export default UserLogin;
