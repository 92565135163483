import type { Dispatch, MutableRefObject, SetStateAction } from 'react';
import useMeasureEnhanced from './useMeasureEnhanced';
import { useClassNameMapper } from 'react-bootstrap';

interface OverflowTrackerResult<E extends HTMLElement = HTMLElement> {
  /**
   * A ref to the container element
   */
  ref: Dispatch<SetStateAction<E>>;

  /**
   * The number of visible items
   */
  visibleCount: number;
}

function applyTemporaryStyle(
  localElement: HTMLElement,
  styles: Partial<CSSStyleDeclaration>
): void {
  Object.entries(styles).forEach(([key, value]) => {
    const existingStyle = localElement.style[key];
    if (existingStyle) {
      localElement.dataset[key] = localElement.style[key];
    }
    localElement.style[key] = value;
  });
}

function removeTemporaryStyle(
  localElement: HTMLElement,
  styles: Partial<CSSStyleDeclaration>
): void {
  Object.keys(styles).forEach(key => {
    const existingStyle = localElement.dataset[key];
    if (existingStyle) {
      localElement.style[key] = existingStyle;
    } else {
      localElement.style[key] = null;
    }
  });
}

/**
 * Enable tracking of the overflowed items
 *
 * @param items Metadata for the items -- must be rendered inside the container for things to work right.
 * See OverflowSet/OverflowNav for examples before determine if the children fit.
 * @param overflowElementRef
 */
export default function useOverflowTracker<
  TItem,
  OverflowElementType extends HTMLElement = HTMLDivElement
>(
  items: TItem[],
  overflowElementRef: MutableRefObject<OverflowElementType> = null
): OverflowTrackerResult {
  const { ref, element: element } = useMeasureEnhanced(true, 500);
  const cx = useClassNameMapper();

  let notVisibleCount = 0;

  const finalElement = overflowElementRef ? overflowElementRef.current : element;

  if (finalElement && process.env.NODE_ENV !== 'test') {
    const validChildren = finalElement.children as unknown as HTMLElement[];

    applyTemporaryStyle(finalElement, { overflowX: 'scroll' });

    for (const child of validChildren) {
      applyTemporaryStyle(child, { display: 'block' });
    }

    // LIA-94334 Elements that are positioned relatively negatively impact the calculation used
    // to determine if an element is overflow the scroll container. To work around this, we temporarily
    // set the position to static so that the scrollWidth is calculated correctly. This
    // applies to dropdowns in the container but in theory could be impacted by other elements that are
    // positioned relatively.
    const dropdowns = finalElement.querySelectorAll(`.${cx('dropdown')}`);

    dropdowns.forEach((dropdown: HTMLElement) => {
      applyTemporaryStyle(dropdown, { position: 'static' });
    });

    for (let i = validChildren.length - 1; i >= 0; i--) {
      const child = validChildren[i];

      if (finalElement.scrollWidth > finalElement.clientWidth) {
        child.style.display = 'none';
        notVisibleCount += 1;
      } else {
        child.style.display = '';
        break;
      }
    }

    removeTemporaryStyle(finalElement, { overflowX: 'scroll' });

    for (const child of validChildren) {
      removeTemporaryStyle(child, { display: 'block' });
    }

    dropdowns.forEach((dropdown: HTMLElement) => {
      removeTemporaryStyle(dropdown, { position: 'static' });
    });
  }

  return {
    ref,
    visibleCount: items.length - notVisibleCount
  };
}
