import type { EndUserQueryParams } from '@aurora/shared-types/pages/enums';
import { EndUserPages } from '@aurora/shared-types/pages/enums';
import type { BaseRouteAndParams } from '@aurora/shared-utils/helpers/urls/NextRoutes/Route';
import { getLog } from '@aurora/shared-utils/log';
import type { ReactElement } from 'react';
import React, { useCallback, useRef } from 'react';
import { Dropdown, Nav, useClassNameMapper } from 'react-bootstrap';
import type { SelectCallback } from 'react-bootstrap/lib/esm/helpers';
import { SharedComponent } from '../../../enums';
import { offsetPopperConfig } from '../../../helpers/ui/PopperJsHelper';
import Icons from '../../../icons';
import type { AdminQueryParams } from '../../../routes/adminRoutes';
import { AdminPages } from '../../../routes/adminRoutes';
import type { CustomLinkProps } from '../../../routes/buildCustomLink';
import useAdminUserRoutes from '../../../routes/useAdminRoutes';
import type { RouteWithOptions } from '../../../routes/useCustomRouter';
import useEndUserRoutes from '../../../routes/useEndUserRoutes';
import useOverflowTracker from '../../useOverflowTracker';
import useTranslation from '../../useTranslation';
import { IconColor, IconSize } from '../Icon/enums';
import Icon from '../Icon/Icon';
import localStyles from './OverflowNav.module.pcss';
import Button from '../Button/Button';
import { ButtonVariant } from '../Button/enums';

export interface OverflowNavItemProps<
  RouteType extends EndUserPages | AdminPages,
  UrlQueryParamType extends EndUserQueryParams | AdminQueryParams
> {
  /**
   * Value used to identify the selected menu item. Must be unique within the array.
   */
  eventKey: string;
  /**
   * The text to be rendered for the <Nav.Link> or <Dropdown.Item>.
   */
  title: string;
  /**
   * Whether the tab is disabled
   */
  disabled?: boolean;
  /**
   * Role applied to the tab link
   */
  role?: string;
  /**
   * Individual route for a given tab.
   */
  route?: RouteWithOptions<RouteType, UrlQueryParamType>;
  /**
   * When using a route change, determines whether the route
   * change should replace the current entry in the history or
   * add a new one.
   */
  recordHistory?: boolean;
  /**
   * aria-label to read by screen reader
   */
  ariaLabel?: string;
}

interface Props<
  RouteType extends EndUserPages | AdminPages,
  UrlQueryParamType extends EndUserQueryParams | AdminQueryParams
> {
  /**
   * An array containing the props applied to every <Nav.Link> or <Dropdown.Item>.
   */
  navItems: Array<OverflowNavItemProps<RouteType, UrlQueryParamType>>;
  /**
   * The initially active tab. Must match an `eventKey` from any one of the provided `navItems`.
   */
  activeTab: string;
  /**
   * Whether to the main wrap has a border.
   */
  useBottomBorder?: boolean;
  /**
   * Whether to indicate the currently active tab with a border.
   */
  useActiveTabBottomBorder?: boolean;
  /**
   * Class name(s) to apply to the <Nav>.
   */
  className?: string;
  /**
   * Callback when a tab is selected.
   */
  onSelect?: SelectCallback;
  /**
   * Class name(s) to apply to the <Nav.Link>.
   */
  navLinkClassName?: string;
  /**
   * Class name(s) to apply to the <Nav.Link> when active.
   */
  navLinkActiveClassName?: string;
}

const log = getLog(module);

/**
 * A menu that uses the Resize Observer API to place overflowing <Nav.Link> items in a dropdown menu.
 *
 * @constructor
 *
 * @author Jonathan Bridges
 */
const OverflowNav = <
  RouteType extends EndUserPages | AdminPages,
  UrlQueryParamType extends EndUserQueryParams | AdminQueryParams
>({
  className,
  navItems,
  activeTab,
  useBottomBorder = true,
  useActiveTabBottomBorder = true,
  navLinkClassName,
  navLinkActiveClassName,
  onSelect
}: Props<RouteType, UrlQueryParamType>): React.ReactElement => {
  const cx = useClassNameMapper(localStyles);
  const { formatMessage, loading: textLoading } = useTranslation(SharedComponent.OVERFLOW_NAV);
  const { Link: EndUserLink, loading: loadingRoutes } = useEndUserRoutes();
  const { Link: AdminUserLink } = useAdminUserRoutes();
  const overflowElementRef = useRef<HTMLDivElement>();

  const { visibleCount, ref } = useOverflowTracker(navItems, overflowElementRef);
  const dropdownItems = navItems.slice(visibleCount);

  /**
   * The handler called when a tab is selected.
   *
   * @param eventKey the tab selected
   * @param event the event
   */
  const selectHandler = useCallback(
    async (eventKey: string | null, event: React.SyntheticEvent<unknown>) => {
      if (onSelect) {
        onSelect(eventKey, event);
      }
    },
    [onSelect]
  );

  if (textLoading || loadingRoutes) {
    return null;
  }

  /**
   * Renders the nav item using the Link component from either the end-user or admin app router
   *
   * @param eventKey the tab selected
   * @param route the link route
   * @param children children for the Link component
   * @param recordHistory whether to record history when the link is used
   */
  function renderNavItemWithLink(
    eventKey: string,
    route: RouteWithOptions<RouteType, UrlQueryParamType>,
    children: ReactElement,
    recordHistory: boolean
  ): ReactElement {
    const { route: routeName, params, query, options } = route;
    const { shallow, locale, scroll } = options ?? {};

    const sharedLinkProps: Omit<
      CustomLinkProps<RouteType, BaseRouteAndParams<RouteType>>,
      'route'
    > = {
      params,
      query,
      shallow,
      locale,
      scroll,
      passHref: true,
      replace: !recordHistory
    };

    if (routeName in EndUserPages && routeName in AdminPages) {
      log.error(
        `The route ${routeName} exists for both enduser and admin applications. Please update the enums to be exclusive`
      );
      return null;
    } else if (routeName in EndUserPages) {
      return (
        <EndUserLink
          key={eventKey}
          route={routeName as EndUserPages}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...sharedLinkProps}
        >
          {children}
        </EndUserLink>
      );
    } else if (routeName in AdminPages) {
      return (
        <AdminUserLink
          key={eventKey}
          route={routeName as AdminPages}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...sharedLinkProps}
        >
          {children}
        </AdminUserLink>
      );
    } else {
      log.error(
        `The provided route: ${JSON.stringify(route)} does not exist for either application`
      );
      return null;
    }
  }

  /**
   * Renders a nav item for either the inline view or in a dropdown menu.
   *
   * @param navItemProps the nav item props
   * @param asDropdownItem whether the nav item should be rendered as a dropdown item.
   * @param isHidden whether the element is hidden
   */
  function renderNavItem(
    navItemProps: OverflowNavItemProps<RouteType, UrlQueryParamType>,
    asDropdownItem: boolean,
    isHidden: boolean
  ) {
    const { disabled, role, eventKey, title, route, recordHistory, ariaLabel } = navItemProps;
    const as = route ? 'a' : 'button';
    let navItem: ReactElement;
    const testId = `OverflowNav.${title}`;

    if (asDropdownItem) {
      navItem = (
        <Dropdown.Item
          as={as}
          type={as === 'button' ? 'button' : undefined}
          key={eventKey}
          active={activeTab === eventKey}
          data-testid={testId}
          disabled={disabled}
          eventKey={eventKey}
          className={cx('lia-dropdown-item')}
          role={role}
          aria-label={ariaLabel}
        >
          {title}
        </Dropdown.Item>
      );
    } else {
      navItem = (
        <Nav.Link
          active={activeTab === eventKey}
          as={as}
          className={cx(
            'lia-nav-btn',
            navLinkClassName,
            {
              'lia-is-active': activeTab === eventKey
            },
            activeTab === eventKey ? navLinkActiveClassName : null,
            {
              'lia-is-borderless': !useActiveTabBottomBorder
            },
            { 'lia-is-hidden': isHidden }
          )}
          disabled={disabled}
          eventKey={eventKey}
          key={eventKey}
          role={role}
          data-testid={testId}
          aria-label={ariaLabel}
        >
          {title}
        </Nav.Link>
      );
    }
    if (route) {
      return renderNavItemWithLink(eventKey, route, navItem, recordHistory ?? true);
    }
    return navItem;
  }

  /**
   * Renders a dropdown menu with nav items that have overflowed in the UI
   */
  function renderDropdown(): React.ReactElement {
    if (dropdownItems.length > 0) {
      const dropdownActive = dropdownItems.some(item => item.eventKey === activeTab);

      return (
        <Dropdown aria-label={formatMessage('toggleText')} focusFirstItemOnShow="keyboard">
          <Dropdown.Toggle
            as={Button}
            variant={ButtonVariant.UNSTYLED}
            data-testid="OverflowNav.Dropdown.Toggle"
            className={cx(
              'lia-nav-btn lia-nav-dropdown',
              navLinkClassName,
              {
                'lia-is-active': dropdownActive
              },
              {
                'lia-is-borderless': !useActiveTabBottomBorder
              }
            )}
          >
            {formatMessage('toggleText')}
            <Icon
              icon={Icons.ChevronDownIcon}
              color={IconColor.GRAY_900}
              size={IconSize.PX_12}
              className={cx('lia-icon')}
            />
          </Dropdown.Toggle>
          <Dropdown.Menu
            className={cx('lia-nav-dropdown-menu lia-g-popper-right')}
            popperConfig={offsetPopperConfig(0, -5)}
            align="right"
            renderOnMount
          >
            {dropdownItems.map(dropdownItem => renderNavItem(dropdownItem, true, false))}
          </Dropdown.Menu>
        </Dropdown>
      );
    }
    return null;
  }

  return (
    <Nav
      className={cx(
        'lia-nav',
        {
          'lia-has-border': useBottomBorder
        },
        className
      )}
      onSelect={selectHandler}
      role="tablist"
      ref={ref}
    >
      <div className={cx('lia-items')} ref={overflowElementRef}>
        {navItems.map((navItem, index) => renderNavItem(navItem, false, index >= visibleCount))}
      </div>
      <div className={cx('lia-more')}>{renderDropdown()}</div>
    </Nav>
  );
};

export default OverflowNav;
