export enum GuideCardSize {
  XS = 'xs',
  SM = 'sm',
  LG = 'lg'
}

export enum GuideType {
  STANDARD = 'standard',
  MANUAL_SORT = 'manualSort'
}
