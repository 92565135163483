import { TextAlignment } from '@aurora/shared-types/texts/enums';
import type { GuideViewCardProps } from '../type';
import { GuideCardSize } from '../../enums';

const defaultViewProps: Partial<GuideViewCardProps> = {
  useDescription: true,
  useGuideTitle: true,
  useAvatar: true,
  clampDescriptionLines: 1,
  textAlignment: TextAlignment.CENTER,
  cardSize: GuideCardSize.LG
};

export default defaultViewProps;
