import { IconSize } from '@aurora/shared-client/components/common/Icon/enums';
import { TagInlineMetadata } from '../../../../types/enums';
import type { TagViewInlineProps } from '../types';

const defaultViewProps: Partial<TagViewInlineProps> = {
  useIcon: true,
  iconSize: IconSize.PX_16,
  useIconFrame: false,
  useLastActivityTime: true,
  usePostCount: true,
  inlineMetadata: TagInlineMetadata.NONE,
  node: null,
  useNode: false
};

export default defaultViewProps;
