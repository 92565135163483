import type { GuideViewInlineProps } from '../type';

const defaultViewProps: Partial<GuideViewInlineProps> = {
  useDescription: true,
  useGuideTitle: true,
  useIcon: true,
  clampDescriptionLines: 1
};

export default defaultViewProps;
